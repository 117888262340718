import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { OAUTH_CONFIG } from '../token';
import { API_BASE } from '@core/api';
import { matchAll, matchNone } from '../util/matchUrl';

export const authenticationInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const apiBase = inject(API_BASE);
  const {
    whitelist = [new URL(apiBase).hostname],
    blacklist = [/oauth\/token/],
  } = inject(OAUTH_CONFIG);

  if (matchAll(whitelist, req.url) && matchNone(blacklist, req.url)) {
    const auth = inject(AuthService);
    return auth.token$.pipe(
      mergeMap((token) => {
        const modifiedReq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`),
        });
        return next(modifiedReq);
      })
    );
  }
  return next(req);
};
